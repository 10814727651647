









































import Vue from "vue";
import Timer from "@/components/Timer.vue";
import Footer from "@/components/navigation-footer.vue";
import draggable from "vuedraggable";
export default Vue.extend({
  data: function () {
    return {
      prioritized: this.$store.state.sentences,
      drag: false,
    };
  },
  components: {
    Timer,
    Footer,
    draggable,
  },
  computed: {
    dragIcon: function () {
      return require("@/assets/img/grab.png");
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
    this.$store.dispatch("SetLogoBottomStyle", { color: "light" });
  },
  methods:{
      UpdatePrioritized:function(){
          this.$store.dispatch("UpdatePrioritized", {sentences:this.prioritized})
      }
  }
});
